import React, { useState, useEffect, useRef, useCallback, useReducer } from "react"
import styled from "styled-components"
import bg from '../assets/images/bg3.svg'

export default function Block4() {
  return (
    <Wrapper>

    </Wrapper>
  )
}

const Wrapper = styled.div`
  min-height: 1170px;
`
