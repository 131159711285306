import React, { useState, useEffect, useRef, useCallback, useReducer } from "react"

import styled from "styled-components"

export default function BlockFooter() {
  return (
    <Wrapper>
      
    </Wrapper>
  )
}

const Wrapper = styled.div`
  background-color: #333333;
  min-height: 480px;
`
