import React, { useState, useEffect, useRef, useCallback, useReducer } from "react"

import styled from "styled-components"
import './../fonts/stylesheet.css';


export default function Screen({children, ...rest}) {
  return (
    <Wrapper>
      {children}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  font-family: Montserrat;
`
