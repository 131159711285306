import React, { useState, useEffect, useRef, useCallback, useReducer } from "react"

import styled from "styled-components"
import { Content } from "../content"
const bsImage = require('./images/about__icon.svg')

export default function Articles() {
  return (
    <Wrapper>
      <Content>
       <WrapperBg>
         <Title>О нас пишут</Title>
         <ArticlesContainer>
           <Article>
             <ArticleImage/>
             <ArticlesDescription>iProxy.Online — делаем мобильные Proxy из любого Android телефона</ArticlesDescription>
           </Article>

           <Article>
             <ArticleImage/>
             <ArticlesDescription>iProxy.Online — делаем мобильные Proxy из любого Android телефона</ArticlesDescription>
           </Article>

           <Article>
             <ArticleImage/>
             <ArticlesDescription>iProxy.Online — делаем мобильные Proxy из любого Android телефона</ArticlesDescription>
           </Article>

           <Article>
             <ArticleImage/>
             <ArticlesDescription>iProxy.Online — делаем мобильные Proxy из любого Android телефона</ArticlesDescription>
           </Article>

           <Article>
             <ArticleImage/>
             <ArticlesDescription>iProxy.Online — делаем мобильные Proxy из любого Android телефона</ArticlesDescription>
           </Article>
         </ArticlesContainer>
       </WrapperBg>
      </Content>
    </Wrapper>
  )
}

const Wrapper = styled.div``
const WrapperBg = styled.div`
  background-image: url(${bsImage});
  background-repeat: no-repeat;
  background-position: -110px -28px;
  padding-top: 50px;
`
const Title = styled.h3`
font-weight: bold;
font-size: 48px;
text-align: center;
letter-spacing: -0.827586px;
color: #1D293F;
`

const ArticlesContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-bottom: 120px;
`

const ArticlesDescription = styled.div`
  width: 100%;
  flex: 1;
  padding: 0 20px;
`

const Article = styled.div`
  background: #FFFFFF;
  cursor: pointer;
  padding: 15px;
  border: 2px solid #F7F8FA;
  border-radius: 5px;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: #1D293F;
  flex: 1 1 40%;
  margin: 10px;
  min-width: 300px;
  alignment: center;
  align-items: center;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  flex-direction: row;
    
  :hover{
    box-shadow: 0px 30px 64px rgba(23, 48, 164, 0.1);
    text-decoration: underline;
  }
`

const ArticleImage = styled.div`
  background-color: #fff;
  width: 80px;
  height: 80px;
  border: 1px solid #F7F8FA;
`
