import React, { useState, useEffect, useRef, useCallback, useReducer } from "react"
import moment from "moment"
import uuid from "uuid"
import styled from "styled-components"

export default function Review({
  title,
  position,
  description,
  logo,
  icon
                               }) {
  return (
    <Wrapper>
      <Content>
        <Customer>
          <CustomerInfo>
            <Icon icon={icon}/>
            <CustomerDetails>
              <Name>{title}</Name>
              <Work>{position}</Work>
            </CustomerDetails>
          </CustomerInfo>
          <Logo icon={logo}/>
        </Customer>

        <Decription>{description}</Decription>
      </Content>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  max-width: 420px;
`

const Content = styled.div`
padding: 15px;
background: #FFFFFF;
border: 1px solid #E2E4E8;
border-radius: 10px;
`

const Customer= styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`

const CustomerInfo = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`

const CustomerDetails = styled.div`
    display: flex;
    flex-direction: column;
    padding-left: 15px;
`

const Icon = styled.div`
  background-color: red;
  width: 41px;
  height: 41px;
  border-radius: 50px;
`
const Logo = styled.div`
  width: 110px;
  height: 40px;
  background-color: red;
`
const Name = styled.div`
font-size: 18px;
line-height: 32px;
color: #1D293F;
`

const Work = styled.div`
  font-size: 13px;
  line-height: 20px;
  color: #7C8087;
`
const Decription = styled.div`
font-size: 14px;
line-height: 26px;
color: #7C8087;
margin-top: 15px;
`
