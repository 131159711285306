import React, { useState, useEffect, useRef, useCallback, useReducer } from "react"
import bg from '../assets/images/bg3.svg'

import styled from "styled-components"

export default function Block5() {
  return (
    <Wrapper>

    </Wrapper>
  )
}

const Wrapper = styled.div`
 background-image: url(${bg});
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 760px;
`
