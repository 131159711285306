import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled from 'styled-components';
import FormattedMessage from "../../elements/FormattedMessage"

export default function FooterSection(props) {


    return (
        <Wrapper>

            <InnerPlaceholder>

                <TopPlaceholder>
                    <LogoImg src={require('./images/EDCT.svg')}/>
                    <PayImg src={require('./images/payments_svg.svg')}/>
                </TopPlaceholder>

                <FooterLinksPlaceholder>
                    {
                        [
                            {
                                name: <FormattedMessage id="topSection.menu.buy"/>,
                                link: `#buy`
                            },
                            {
                                name: <FormattedMessage id="topSection.menu.sell"/>,
                                link: `#sell`
                            },
                            {
                                name: <FormattedMessage id="topSection.menu.reviews"/>,
                                link: `#reviews`
                            },
                            {
                                name: <FormattedMessage id="topSection.menu.faq"/>,
                                link: `#faq`
                            },
                            {
                                name: <FormattedMessage id="topSection.menu.contacts"/>,
                                link: `#contacts`
                            }
                        ].map((a, i) => {
                            return (
                                <FooterLink href={a.link} key={i}>
                                    {a.name}
                                </FooterLink>
                            );
                        })
                    }

                </FooterLinksPlaceholder>

                <TripletsPlaceholder id="contacts">

                    {
                        [
                            {
                                color: '#FF911D',
                                label: 'Telegram',
                                text: '@proxybroker',
                                link: 'https://t.me/proxybroker',
                                image: require('./images/send.svg')
                            },
                            {
                                color: '#5D77DA',
                                label: 'Email',
                                text: 'ceo@proxy.broker',
                                link: 'mailto:hello@proxy.broker',
                                image: require('./images/mail.svg')
                            },
                            {
                                color: '#FFD648',
                                label: 'Telegram',
                                text: '@proxy_broker_bot',
                                link: 'https://t.me/proxy_broker_bot',
                                image: require('./images/send.svg')
                            },

                        ].map((a, i) => {
                            return (
                                <TripletItem key={i}>
                                    <ItemImg src={a.image}/>
                                    <ItemText color={a.color} onClick={() => {
                                        window.open(a.link, '_blank');
                                    }}>
                                        {a.text}
                                    </ItemText>
                                    <ItemLabel >
                                        {a.label}
                                    </ItemLabel>
                                </TripletItem>
                            );
                        })
                    }

                </TripletsPlaceholder>


                <BottomPlaceholder>
                    <BottomLeft>
                        <VeryBottomItem>
                            <LangSpan>
                                <img src={require('./images/flag_im.svg')} style={{height: 12, marginRight: 10}}/>
                                <FormattedMessage id="lang.en"/>
                            </LangSpan>
                        </VeryBottomItem>
                    </BottomLeft>
                    <BottomRight>
                        <VeryBottomItem>
                            <Link href="https://drive.google.com/file/d/15hpiz_42I5YafwmSmLhjCQLSMviLtwaa/view?usp=sharing"><FormattedMessage id="policy"/></Link>
                        </VeryBottomItem>
                        <VeryBottomItem>
                            <Link href="https://drive.google.com/file/d/1yk_vQxrUSii797m_jvRmqtCdVB24G68P/view?usp=sharing"><FormattedMessage id="terms"/></Link>
                        </VeryBottomItem>
                    </BottomRight>
                </BottomPlaceholder>

            </InnerPlaceholder>

        </Wrapper>
    );
}

const Link = styled.a`
 color: #fff;
 cursor: pointer;
 text-decoration: none;
 transition: .3s;

  :hover {
        color: #ff901f;
    }
`

const ItemText = styled.div`
    font-weight: bold;
    font-size: 24px;
    line-height: 34px;
    text-align: center;
    letter-spacing: 1px;
    color: ${props => props.color};
    margin-top: 15px;
    margin-bottom: 15px;
    cursor: pointer;
    transition: .4s;
    :hover {
      color: #fff;
    }
`;

const ItemLabel = styled.div`
    font-size: 18px;
    line-height: 28px;
    text-align: center;
    color: #FFFFFF;
`;

const TripletsPlaceholder = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding-top: 50px;
    justify-content: center;
    padding-bottom: 50px;
    
    @media only screen and (max-width: 720px) {
      flex-direction: column;
      align-items: center;
      padding-bottom: 0;
    }  
`;

const ItemImg = styled.img`
    
`;

const TripletItem = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 300px;
    
    @media only screen and (max-width: 720px) {
      margin-bottom: 30px;
    }  
`;

const LangSpan = styled.div`
    transition: .3s;
    cursor: pointer;
    :hover {
        color: #ff901f;
    }
`;

const breakWidth = 1100;

const VeryBottomItem = styled.div`
    margin-right: 20px;
    :last-of-type{
      margin-right: 0px;
    }
    
      @media only screen and (max-width: 500px) {
      margin: 0 0 10px;
    }
    
`;

const BottomLeft = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;  
`;

const BottomRight = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    
    
     @media only screen and (max-width: 500px) {
      flex-direction: column;
      justify-content: center;
      margin-top: 25px;
    }
`;

const BottomPlaceholder = styled.div`
    margin-top: 30px;
    padding-top: 30px;
    border-top: 1px solid rgba(255, 255, 255, 0.070613);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    color: white;
    
    @media only screen and (max-width: 500px) {
      flex-direction: column;  
    }  
`;

const Wrapper = styled.div`
    background: #333333;
    box-sizing: border-box;
    padding: 40px;
`;

const InnerPlaceholder = styled.div`
    width: ${breakWidth}px;
    margin: 0 auto;
    box-sizing: border-box;
    @media(max-width: ${breakWidth}px){
      width: 100%;
    }
`;


const TopPlaceholder = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 35px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.070613);
    margin-bottom: 40px;
    
    
`;

const FooterLinksPlaceholder = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: white;
    
    @media only screen and (max-width: 880px) {
        flex-direction: column;
        border-bottom: 1px solid rgba(255,255,255,0.070613);
        padding-bottom: 40px;
    }
`;

const FooterLink = styled.a`
    margin-left: 35px;  
    margin-right: 35px;
    text-decoration: none;
    color: #fff;
    transition: .3s;
    @media only screen and (max-width: 880px) {
      padding: 10px 0;
    }  
    
    :hover {
        color: #ff901f;
    }
`;

const TopLogoImg = styled.img`
    
`;

const PayImg = styled.img`
    height: 40px;
    @media only screen and (max-width: 560px) {
       margin: auto;
       width: 100%;
    } 
`;

const LogoImg = styled.img`
    height: 48px;
    width: 168px;
    margin-right: 50px;
    margin-bottom: 0px;
    
    @media only screen and (max-width: 560px) {
       display: none;
    } 
`;
