import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import axios from 'axios'
import { Content } from "../content"
import FormattedMessage from "../../elements/FormattedMessage"

const gilImg = require('./images/gil.svg');

export default function JohnSection(props) {


    return (
        <Wrapper>
            <Content>
                <ContentWrapper>
                    <Left>
                        <PrxImg src={require('./images/prx_im_srv.svg')} />
                        <SucMan src={require('./images/sq_man_.png')}/>
                        <GilPlaceholder>
                            <GilInnerPlaceholder>
                                <FomName>
                                     <FormattedMessage id="johnSection.ev.name"/>
                                </FomName>
                                <FomLabel>
                                    <FormattedMessage id="johnSection.ev.title"/>
                                </FomLabel>

                                <HorImg src={require('./images/hor_line.svg')} />

                                <MentPlaceholder>
                                    <FormattedMessage id="johnSection.ev.position.a"/> <Link href="https://iproxy.online/" target="_blank"><FormattedMessage id="johnSection.ev.position.b"/> </Link><FormattedMessage id="johnSection.ev.position.c"/>
                                </MentPlaceholder>
                            </GilInnerPlaceholder>

                        </GilPlaceholder>
                    </Left>
                    <Right>
                        <RightMotto>
                            <b>
                                <span style={{color: '#FF911D', fontWeight: '600'}}>PROXY</span><span style={{color: '#020089', fontWeight: '600'}}>.broker</span>
                            </b>
                            &nbsp;&mdash; <FormattedMessage id="johnSection.desc"/>
                        </RightMotto>

                        <RelaxImg src={require('./images/byev__icon.svg')} />

                    </Right>
                </ContentWrapper>
            </Content>
        </Wrapper>
    );
}

const Wrapper = styled.div`
 
`

const Link = styled.a`
 color: #ff9d41;
 text-decoration: none;
 transition: .2s;
 :hover {
 color: rgb(2, 0, 137);
 }
`

const RelaxImg = styled.img`
    position: absolute;
    right: 0px;
    bottom: 0px;
    width: 380px;
    
    @media only screen and (max-width: 1150px) {
      display: none;
    }
`;

const RightMotto = styled.div`
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: -1px;
    color: #1D293F;
    
   
    @media only screen  and (min-width: 500px) and (max-width: 920px) {
       padding: 0px 15px;
       margin-top: 40px;
    }
    
      @media only screen and (max-width: 500px) {
       padding: 0px 15px;
       margin-top: 40px;
       font-size: 18px;
    }
`;

const ContentWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: stretch;
    margin: 0 auto;
    margin-top: 120px;
    box-sizing: border-box;
    
    @media only screen and (max-width: 920px) {
      flex-direction: column;
      margin-top: 0;
    }
`;

const NamePlaceholer = styled.div`
    
`;

const MentPlaceholder = styled.div`
     @media only screen and (max-width: 500px) {
      font-size: 12px;
      text-align: left;
      padding-left: 55px;
    }
`;

const HorImg = styled.img`
    margin-top: 30px;
    margin-bottom: 30px;
     @media only screen and (max-width: 500px) {
        width: 65%;
        margin-top: 15px;
        margin-bottom: 5px;
     }
   
`;

const FomLabel = styled.div`
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    color: #8C97AC;
    
     @media only screen and (max-width: 500px) {
      font-size: 12px;
      padding-left: 55px;
      text-align: left;

    }
`;

const FomName = styled.div`
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 34px;
    color: #1D293F;
    
     @media only screen and (max-width: 500px) {
      font-size: 18px;
      text-align: left;
      padding-left: 55px;
      font-weight: 600;
    }
`;



const GilPlaceholder = styled.div`
    position: absolute;
    top: 300px;
    left: 360px;
    box-sizing: border-box;
    z-index: 2;
    
     @media only screen and (max-width: 1050px) {
      bottom: 0;
      left: 0;
    }
    
  
`;

const GilInnerPlaceholder = styled.div`
    background-image: url(${gilImg});
    background-repeat: no-repeat;
    background-size: contain;
    padding: 40px;
    height: 218px;
    padding-left: 80px;
    padding-right: 80px;
    
     @media only screen  and (min-width: 500px)  and (max-width: 1050px) {
         margin-top: 120px;
        margin-left: 60px;
     }
    @media only screen  and (min-width: 370px)  and (max-width: 500px) {
        width: 300px;
        margin-left: 15%;
        box-sizing: border-box;
        padding: 20px 0 0;
    }
    
     @media only screen and (max-width: 370px) {
     width: 300px;
        margin-left: -10px;
        box-sizing: border-box;
        padding: 20px 0 0;
     }
`;

const Left = styled.div`
    position: relative;
    width: 440px;
    
     @media only screen and (min-width: 500px) and (max-width: 920px) {
      margin: auto;
    }
    
     @media only screen and (max-width: 500px) {
      margin: auto;
      width: 100%;
      text-align: center;
    }
`;

const Right = styled.div`
    flex: 1;
    padding-left: 55px;
    padding-top: 90px;
    text-align: justify;
    position: relative;
    
    @media only screen and (min-width: 500px)  and (max-width: 920px) {
      padding: 40px 0 0;
    }
    
    @media only screen and (max-width: 500px) {
      padding: 70px 5px 0;
    }
`;

const SuccessImage = styled.img`
    position: absolute;
`;

const PrxImg = styled.img`
    position: absolute;
    left: -40px;
    top: -40px;
     @media only screen and (min-width: 920px) and (max-width: 1340px) {
      display: none;
     }
    @media only screen and (max-width: 500px) {
      width: 70px;
      height: 60px;
      top: -20px;
      left: 0;
    }
`;

const SucMan = styled.img`
    width: 440px;
    
    @media only screen and (max-width: 500px) {
      max-width: 270px;
      margin: auto;
      text-align: center;
    }
`;
