import React, { useState, useEffect, useRef, useCallback, useReducer } from "react"
import styled from "styled-components"



export default function PriceTable() {
  const demo = [
    {
      title: 'Мобильные прокси России',
      price: 157,
      icon: ''
    }
  ]
  const OfferLine = ({...props}) => {
    const {title, icon, price} = props.value;
    return (
      <LineWrapper>
        <Block position="flex-start">
          <IconCountry icon={icon}/>
          <CountryTitle>{title}</CountryTitle>
        </Block>
        <Block position="flex-end">
          <Price>${price} руб/шт</Price>
          <Status>В наличии</Status>
          <Action>Хочу</Action>
        </Block>
      </LineWrapper>
    );
  }

  return (
    <Wrapper>
      <Content>
        <Title>Наши прокси</Title>
        <Options>
          {demo.map(i => <OfferLine value={i}/>)}
        </Options>
      </Content>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  padding: 20px;
  width: 100%;
`

const Title = styled.h3`
font-size: 36px;
line-height: 56px;
text-align: center;
letter-spacing: -0.827586px;
color: #1D293F;
margin-top: 10px;
`;

const Content = styled.div`
  background: #FFFFFF;
  box-shadow: 0px 30px 64px rgba(23, 48, 164, 0.1);
  border-radius: 20px;
  max-width: 730px;
  padding: 10px;
`

const Options = styled.div`
  display: flex;
  flex-direction: column;
`


const LineWrapper = styled.div`
  padding: 10px;
  font-size: 16px;
  line-height: 30px;
  color: #7C8087;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #F2F1F0;
`


const Block = styled.div`
  display: flex;
  flex: 1;
  align-items: baseline;
  justify-content: ${props => props.position};
`
const CountryTitle = styled.div`
  padding: 0 10px;
`
const IconCountry = styled.div`
  width: 21px;
  height: 14px;
  background-color: red;
`
const Price = styled.div`
  margin-right: 30px;
`
const Status = styled.div`
  color: #505CD4;
  font-weight: bold;
  padding: 0 20px;
`
const Action = styled.div`
  color: #FEBB46;
  font-weight: bold;
  cursor: pointer;
`
