import React, { useState, useEffect, useRef, useCallback, useReducer } from "react"

import styled from "styled-components"
import FeatureContent from "../elements/feature-content"
import FeatureContentSlim from "../elements/feature-content-slim"
import PriceTable from "../components/price-table"
import Review from "../elements/review"

export default function Block2() {
  return (
    <Wrapper>
      <FeatureContent
        icon={null}
        title={'Брокер Гарантирует'}
        description={' если на стороне продавца будут просадки (например покупатель купил на 30 дней, а 20 часов из 30 дней  - не работало, то Брокер Автоматом продлит действие прокси на эти 20 часов'}/>
      <FeatureContentSlim/>
      <PriceTable/>
      <Review position={'CEO'} title={'Aaron Almaraz'} description={'desc'}/>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  background-color: #fff;
  min-height: 920px;
`
