import ruMessages from '../intl/ru'
import enMessages from '../intl/en'


export function generateLanguage() {
  const lang = process.env.GATSBY_LANG_ENV;
  return {
    locale: lang,
    formatMessage: ({id}) => lang === 'ru' ? ruMessages[id] : enMessages[id]
  }
}
