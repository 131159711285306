import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import axios from 'axios'
import { Content } from "../content"
import bgTopIcon from './images/footer_top__icon.svg'
import bgFooterIcon from './images/man_w_laptop_last.svg'
import FormattedMessage from "../../elements/FormattedMessage"

export default function FaqSection(props) {

    const faqList = [
        {
            question: 'Сколько стоят ваши прокси?',
            answer: 'Цены назначают продавцы самостоятельно. Предложения продавцов сортируются по цене. Вы можете выбрать лучших по соотношению цена/рейтинг продавца\n'
        }, {
            question: 'Чем ваш сервис лучше других? \n',
            answer: 'Мы предоставляем мобильные прокси по всему миру по максимально низким ценам. Денежные средства покупатели переводят напрямую продавцам, что исключает лишние издержки, в отличие от варианта, в котором вы бы переводили денежные средства сначала веб-сайту, а веб-сайт затем распределял бы их по продавцам. Пользуясь нашим сервисом, вы избегаете крупных комиссий на денежных переводах и выводах средств. В наше время чем дешевле, тем лучше. А надежность обеспечивают верификация продавцов и их рейтинг.\n'
        }, {
            question: 'Почему нужно работать именно через вас, а не напрямую? \n',
            answer: 'Работая через PROXY.broker покупатель получает весь спектр функций, необходимых  при работе с прокси: смена IP-адреса всеми возможными способами, API, Telegram-бот, IP History, Авторизация по IP-адресу, Поддержка HTTP/SOCKS5 и многое другое. Продуманные правила использования и Политика конфиденциальности регулируют ответственность между покупателями и продавцами.\n' +
              '<br><br>' +
              'Работая через PROXY.broker продавец получает весь спектр функций для продажи и управления прокси: Black/White-листы, логи, формирование рейтинга, отзывов, независимую проверку качества предоставляемых проксей, виджет PROXY.broker для встраивания на свои сайты и многое другое. Продуманные правила использования и Политика конфиденциальности регулируют ответственность между покупателями и продавцами.\n'
        }, {
            question: 'Как я могу с вами зарабатывать?',
            answer: 'Если у вас уже есть поднятые мобильные прокси-фермы, то разместите свое Предложение в PROXY.broker и получайте заявки от покупателей!\n' +
              '<br><br>' +
              'Если у вас еще нет ферм, но вы хотите зарабатывать, вы можете очень легко поднять мобильные прокси-фермы самостоятельно на USB-модемах или при помощи iProxy.online на Андроид-телефонах. Создавайте прокси и находите покупателей в PROXY.broker.'
        }, {
            question: 'Как мне быть уверенным в надежности продавца?',
            answer: 'У каждого продавца формируется рейтинг: отзывы на прошедшие сделки и их оценки, автоматическая проверка качества прокси: uptime, speedtest, который проводится независимо PROXY.broker-ом. Количество активных сделок в данный момент и многое другое.\n'
        }, {
            question: 'Могут ли мои прокси быть использованы кем-то ещё? \n',
            answer: 'Нет, мы контролируем, чтобы ваши прокси оставались приватными. \n'
        }, {
            question: 'Как происходит оплата прокси?\n',
            answer: 'Создавайте переписку с продавцом в PROXY.broker, обсуждайте условия работы и цены. Денежные средства пересылаются напрямую продавцу. Проверяйте рейтинг продавца перед отправкой денежных средств.\n'
        }, {
            question: 'Прокси перестали работать, к кому обращаться? Кто компенсирует мне просадку? \n',
            answer: 'PROXY.broker отслеживает Uptime прокси. Если произойдут просадки, то вам автоматически продлится действие прокси.\n'
        }, {
            question: 'Какие у вас прокси? Приватные или Общие?',
            answer: 'Приватные'
        }, {
            question: 'Какие функции поддерживают ваши прокси?\n',
            answer: 'Смена IP всеми возможными способами: по переходу по ссылке, по расписанию, по нажатию на кнопке на сайте, через Telegram-бот, по  API. Подсчёт трафика. Telegram-бот для уведомлений. Поддержка HTTP и SOCKS5. Авторизация по IP-адресу. И многое другое. \n'
        }, {
            question: 'Хочу продавать прокси через вас, но я не знаю с чего начать?',
            answer: 'Напишите нам в техподдержку, и мы обязательно вам поможем!'
        },
    ];


    return (
        <Wrapper >
            <Content >

                <WrapperBg>
                    <Heading id="faq">
                        <FormattedMessage id="faqSection.title"/>
                    </Heading>
                        {faqList.map((a, i) => {
                            return (
                              <Item key={i}>
                                  <FaqName>
                                      {a.question}
                                  </FaqName>
                                  <FaqText dangerouslySetInnerHTML={{__html: a.answer}}/>
                              </Item>
                            )
                        })}
                    <ItemWrapper/>


                    <BottomPlaceholder>
                        <BottomButton onClick={() => {
                            window.location.href = `/app/?signup`;
                        }}>
                            <FormattedMessage id="faqSection.action"/>

                        </BottomButton>
                    </BottomPlaceholder>
                </WrapperBg>

            </Content>
        </Wrapper>
    );
}

const ItemWrapper = styled.div`
  background-image: url(${bgFooterIcon});
  background-position: center bottom;
  background-repeat: no-repeat;
  padding-bottom: 400px;
    margin-bottom: 50px;
    background-size: contain;
    
    @media only screen and (max-width: 500px) {
      background: none;
      padding-bottom: 0;
      margin-bottom: 10px;
    }
`;
const WrapperBg = styled.div`
  
`;

const BottomPlaceholder = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
`;

const BottomButton = styled.div`
    background: #FF911D;
    box-shadow: 0px 4px 8px rgba(255, 145, 29, 0.2);
    width: 270px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 60px;
    border-radius: 1000px;
    cursor: pointer;
    color: white;
    transition: 0.5s;
    
    :hover{
      opacity: 0.8;
    }
`;

const Item = styled.div`
   padding-bottom: 40px;
    max-width: 1000px;
    margin: auto;
`;


const Wrapper = styled.div`
    box-sizing: border-box;
    background: white;
    padding-top: 170px;
    padding-bottom: 100px;
    
    background-image: url(${bgTopIcon});
    background-repeat: no-repeat;
    background-position-y: 120px;
    margin-bottom: 30px;
    
    @media only screen and (min-width: 500px) and (max-width: 1600px) {
      background-image: none;
    }
    
   @media only screen and (max-width: 500px) {
      padding-top: 70px;
      padding-bottom: 30px;
    background: none;
    }
`;

const Heading = styled.div`
    font-weight: bold;
    font-size: 48px;
    line-height: 56px;
    color: #1D293F;
    margin-bottom: 100px;
    text-align: center;
    
    @media only screen and (max-width: 500px) {
      text-align: center;
      font-size: 24px;
      line-height: 28px;
      margin-bottom: 50px;
    }
`;



const FaqName = styled.div`
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: #2C2E30;
    margin-bottom: 15px;
    
     @media only screen and (max-width: 500px) {
      font-size: 18px;
    }
`;

const FaqText = styled.div`
    font-size: 16px;
    line-height: 26px;
    color: #7C8087;
    
      @media only screen and (max-width: 500px) {
      font-size: 14px;
    }
`;

const FaqItem = styled.div`
    margin-bottom: 80px;
`;


