import React, { useState, useEffect, useRef, useCallback, useReducer } from "react"

import styled from "styled-components"
import { generateLanguage } from "../services/langService"

export default function FormattedMessage({id}) {
  const intl = generateLanguage(process.env.GATSBY_LANG_ENV)
  return (
    <Wrapper dangerouslySetInnerHTML={{__html:  intl.formatMessage({ id }) }} />
  )
}

const Wrapper = styled.span``
