import React, { useState, useEffect, useRef, useCallback, useReducer, useMemo } from "react"
import moment from "moment"
import uuid from "uuid"
import styled from "styled-components"
import axios from "axios"
// import { Content } from "../content"
import { useScrollPosition } from "@n8tb1t/use-scroll-position"
import FormattedMessage from "../../elements/FormattedMessage"
import { Content } from "../content"

const gilImg = require("./images/gil.svg")

export default function WhySection(props) {
  const [animate, setAnimate] = useState(false)

  useScrollPosition(({ prevPos, currPos }) => {
    const yValue = Math.abs(currPos.y)
    if (!animate) {
      setAnimate(yValue >= 1500)
    }
  })

  return (
    <Wrapper>
      <Content>

        <Heading className={animate ? "animate__animated animate__pulse " : ""}>

          <FormattedMessage id="whySection.title"/>

          <span style={{ color: "#FF911D", fontWeight: "600" }}>PROXY</span><span
          style={{ color: "#020089", fontWeight: "600" }}>.broker</span>
        </Heading>

        <TripletsSection>

          {[
            {
              image: require("./images/gar_img.svg"),
              color: "#505CD4",
              name: <FormattedMessage id="whySection.block.1.title"/>,
              text: <FormattedMessage id="whySection.block.1.desc.a"/>,
              subText: <FormattedMessage id="whySection.block.1.desc.b"/>
            },
            {
              image: require("./images/rating_img.svg"),
              color: "#FFBC47",
              name: <FormattedMessage id="whySection.block.2.title"/>,
              text: <FormattedMessage id="whySection.block.2.desc.a"/>,
              subText: <FormattedMessage id="whySection.block.2.desc.b"/>
            },
            {
              image: require("./images/cloud_img.svg"),
              color: "#1631A4",
              name: <FormattedMessage id="whySection.block.3.title"/>,
              text: <FormattedMessage id="whySection.block.3.desc.a"/>,
              subText: <FormattedMessage id="whySection.block.3.desc.b"/>
            }

          ].map((a, i) => {
            return (
              <TripletItem key={i}>
                <BadgePlaceholder>
                  <Badge {...a} >
                    <BadgeImage src={a.image}/>
                  </Badge>
                </BadgePlaceholder>
                <TrName>
                  {a.name}
                </TrName>

                <TrMainText>
                  {a.text}
                </TrMainText>

                <TrSubText>
                  {a.subText}
                </TrSubText>

              </TripletItem>
            )
          })}

        </TripletsSection>

        <BenefitsPlaceholder className={animate ? "animate__animated animate__backInLeft" : "display-none"}>

          {
            [
              "Подсчет трафика",
              "Уведомления о смене IP в Телеграм",
              "Телеграм‑бот для смены IP‑адреса",
              "Поддержка HTTP/SOCKS5",
              "Авторизация по IP‑адресу"
            ].map((a, i) => {
              return (
                <BenefitItem key={i}>
                  <FormattedMessage id={`whySection.feature.${i + 1}.title`}/>
                </BenefitItem>
              )
            })
          }

        </BenefitsPlaceholder>
      </Content>

    </Wrapper>
  )
}



const BenefitsPlaceholder = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 31px;
    margin-bottom: 30px;
    
    @media only screen  and (min-width: 500px)  and (max-width: 880px) {
      flex-wrap: wrap;
    }
    
     @media only screen and (max-width: 500px) {
      flex-direction: column;
      align-items: center;
      padding: 0 15px;
    }
`;

const BenefitItem = styled.div`
    font-size: 16px;
    line-height: 21px;
  
    padding: 13px;
    background: #6774FF;
    border-radius: 10px;
    flex: 1;
    margin: 10px;
    color: white;
    padding-left: 16px;
    padding-right: 16px;
    height: 90px;
 
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-align: center;
    
    @media only screen and (min-width: 500px) and (max-width: 880px) {
      flex: 1 1 40%;
    }
    
     @media only screen and (max-width: 500px) {
      width: 100%;
      font-size: 14px;

   
      
      margin: 0 0 10px;
    }
`;

const TrName = styled.div`
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    color: #1D293F;
    margin-bottom: 25px;
    
     @media only screen and (max-width: 500px) {
     font-size: 18px;
     margin-bottom: 15px;
    }
`;

const TrMainText = styled.div`
    color: #7C8087;
    font-size: 16px;
    line-height: 26px;
    
     @media only screen and (max-width: 500px) {
      font-size: 14px;
      text-align: left;
    }
`;

const TrSubText = styled.div`
    color: #7C8087;
    font-size: 12px;
    line-height: 22px;
    margin-top: 20px;
    
    @media only screen and (max-width: 500px) {
      font-size: 10px;
      margin-top: 10px;
      text-align: left;
    }
`;

const breakWidth = 1100

const BadgePlaceholder = styled.div`
    margin-bottom: 30px;  
`

const BadgeImage = styled.img`
    //width: 39px;
    height: 43px;
`

const Badge = styled.div`
    width: 75px;
    height: 75px;
    border-radius: 4px;
    background: ${props => props.color};
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`

const Wrapper = styled.div`
     margin: 140px auto 0;
     box-sizing: border-box;
     
     @media only screen and (max-width: 500px) {
      margin-top: 60px; 
      }
`;

const Heading = styled.div`
    font-weight: bold;
    font-size: 48px;
    line-height: 56px;
    text-align: center;
    letter-spacing: -0.827586px;
    color: #1D293F;
    margin-bottom: 84px;
    
    @media only screen and (max-width: 500px) {
      font-size: 24px;
      line-height: 28px;
      margin-bottom: 60px;
    }
`;

const TripletsSection = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    @media only screen and (min-width: 500px) and (max-width: 880px) {
      flex-direction: row;
    }
    
     
     @media only screen and (max-width: 500px) {
      flex-direction: column;
    }
`;

const TripletItem = styled.div`
    
    width: calc((100% - 80px) / 3.0);
    padding: 30px;
    box-sizing: border-box;
    border-radius: 10px;
    background: #F7F8FA;
    vertical-align: top;
    
    
    
    @media only screen and (min-width: 500px) and (max-width: 880px) {
      width: 100%;
      margin-right: 0px !important;
      margin-left: 0px !important;
            margin-bottom: 20px;

    }
    
    
    }
    
     @media only screen and (max-width: 500px) {
      width: 100%;
      margin-bottom: 20px;
      
     }
`;
