import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import axios from 'axios'
import { Content } from "../content"
import menuIcon from './images/mdi_menu.svg';
import { generateLanguage } from "../../services/langService"
import FormattedMessage from "../../elements/FormattedMessage"

const waveBg = require('./images/wave.svg')

export default function TopSection(props) {
const [showModal, setShowModal] = useState(false);
const intl = generateLanguage();


    return (
        <Wrapper>

          <Content>
              <HeadingPlaceholder>
                  <TopLeftPlaceholder>

                      <LogoImg src={require('./images/pr_br_logo.svg')}/>

                      <LinksPlaceholder>
                          <LinkItem href="#buy">
                              <FormattedMessage id="topSection.menu.buy"/>

                          </LinkItem>
                          <LinkItem href="#sell">
                              <FormattedMessage id="topSection.menu.sell"/>
                          </LinkItem >
                          <LinkItem href="#reviews">
                              <FormattedMessage id="topSection.menu.reviews"/>
                          </LinkItem>
                          <LinkItem href="#faq">
                              <FormattedMessage id="topSection.menu.faq"/>
                          </LinkItem>
                          <LinkItem href="#contacts">
                              <FormattedMessage id="topSection.menu.contacts"/>
                          </LinkItem>
                      </LinksPlaceholder>

                      <MobileMenuButton/>

                  </TopLeftPlaceholder>
                  <TopRightPlaceholder>
                      <RightLink>
                          <FormattedMessage id="lang.ru"/>
                      </RightLink>
                      <LoginSpan onClick={() => {
                          window.location.href = `/app/?login`;
                      }}>
                          <FormattedMessage id="account.signin"/>
                      </LoginSpan>
                      <SignupButton onClick={() => {
                          window.location.href = `/app/?signup`;
                      }}>
                          <FormattedMessage id="account.signup"/>
                      </SignupButton>
                  </TopRightPlaceholder>
              </HeadingPlaceholder>

              <ContentPlaceholder>

                  <LeftPlaceholder>
                      <Motto>
                          <span style={{color: '#FF911D'}}>
                            <FormattedMessage id="topSection.title.a"/>
                        </span>
                          <FormattedMessage id="topSection.title.b"/>
                          <span style={{color: '#020089'}}>
                            <FormattedMessage id="topSection.title.c"/>
                        </span>
                          <br/>
                          <FormattedMessage id="topSection.title.d"/>
                      </Motto>
                      <SubMotto>
                          <span style={{color: '#FF911D', fontWeight: '600'}}>PROXY</span><span style={{color: '#020089', fontWeight: '600'}}>.broker</span> <FormattedMessage id="topSection.description"/>


                      </SubMotto>

                      <TryButtonsPlaceholder>
                          <TryNowButton onClick={() => {
                              window.location.href = `https://proxy.broker/app/#/`;
                          }}>

                              <FormattedMessage id="topSection.try"/>
                          </TryNowButton>
                          <PlaySpan>
                              <PlayImg src={require('./images/play-button.svg')} />
                              <PlayLabel href="#video-preview">

                                  <FormattedMessage id="topSection.demo"/>
                              </PlayLabel>
                          </PlaySpan>
                      </TryButtonsPlaceholder>

                  </LeftPlaceholder>

                  <RightPlaceholder>
                      <FallosImage src={require('./images/b1__icon.svg')}/>
                  </RightPlaceholder>

              </ContentPlaceholder>

          </Content>


            {showModal && (
              <VideoModal>
                  <VideoModalContainer>
                      <iframe src="https://www.youtube.com/embed/sZELzeuTJIM" frameBorder="0"
                              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                              allowFullScreen></iframe>
                      <CloseModal onClick={() => setShowModal(false)}>Закрыть</CloseModal>
                  </VideoModalContainer>
              </VideoModal>
            )}

        </Wrapper>
    );
}

const CloseModal = styled.div`
    position: absolute;
    bottom: -80px;
    left: calc(50% - 135px);
    background: #FF911D;
    box-shadow: 0px 4px 8px rgba(255, 145, 29, 0.2);
    border-radius: 1000px;
    width: 270px;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 60px;
    margin-right: 42px;
    cursor: pointer;
    font-size: 16px;
    line-height: 19px;
    transition: .4s;
    
    :hover{
      background: #E97800;
    }
    
     @media(max-width: 500px){
      margin: 0;
    }
`

const VideoModal = styled.div`
 width: 100vw;
 height: 100vh;
 background-color: rgba(0,0,0,0.8);
 position: fixed;
 top: 0;
 left: 0;
 display: flex;
 flex-direction: column;
 align-items: center;
 justify-content: center;
 z-index: 10000;
`
const VideoModalContainer = styled.div`
  position: relative;
 background-color: #fff;
 max-width: 700px;
 width: 800px;
 height: 420px;
 padding: 10px;
 iframe {
  width: 100%;
  height: 100%;
 }
 @media(max-width: 820px){
  width: 100%;
 }
 @media(max-height: 520px){
  height: calc(100vh - 100px);
 }
`

const PlaySpan = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
 
  
`;

const PlayLabel = styled.a`
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    text-decoration: none;
    color: #020089;
    border-bottom: 1px solid rgba(2,0,137,0);
    transition: 0.5s;
    
    :hover {
          color: #505CD4;

    }
    
    @media only screen  and (max-width: 500px) {
      font-size: 14px;
      
    }
`;

const PlayImg = styled.img`
    height: 30px;
    width: 30px;
    margin-right: 10px;
`;

const Wrapper = styled.div`
    min-height: 650px;
    height: calc(80vh - 0px);
    background-image: url(${waveBg});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
    
    @media only screen  and (max-width: 500px) {
    height: 540px;
    background-size: 1160px;
        min-height: 550px;
    }
`;

const TryNowButton = styled.div`
    background: #FF911D;
    box-shadow: 0px 4px 8px rgba(255, 145, 29, 0.2);
    border-radius: 1000px;
    width: 270px;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 60px;
    margin-right: 42px;
    cursor: pointer;
    font-size: 16px;
    line-height: 19px;
    transition: 0.5s;
    
    :hover{
      background: #E97800;
    }
    
     @media(max-width: 500px){
      margin: 0;
    }
    
    
    @media only screen  and (max-width: 500px) {
      font-size: 14px;
      width: 170px;
    }
    
    @media only screen  and (max-width: 370px) {
      margin-top: -10px;
      margin-bottom: 20px;
    }
`;

const TryButtonsPlaceholder = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 50px;
    
    @media only screen and (min-width: 500px) and (max-width: 780px) {
    justify-content: center;
   }
   
    
    @media only screen  and (min-width: 370px) and (max-width: 500px) {
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }
    
     @media only screen  and (max-width: 370px) {
      flex-direction: column;
    }
`;

const Motto = styled.h1`
    margin-bottom: 35px;
    font-weight: 500;
    //font-size: 32px;
    font-size: 44px;
    line-height: 50px;
    letter-spacing: -1px;
    color: #1D293F;
    
   @media only screen  and (max-width: 500px) {
    font-size: 19px;
    text-align: left;
    font-weight: 600;
    line-height: 26px;
   }
`;

const SubMotto = styled.h4`
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 30px;
    color: #7C8087;
    width: 600px;
    
    @media only screen and (max-width: 780px) {
    width: 100%;
    text-align: justify;
   }
   
   @media only screen and (max-width: 500px) {
      font-size: 14px;
      line-height: 20px; 
   }
`;

const ContentPlaceholder = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin: 0 auto;
    box-sizing: border-box;
`;

const LeftPlaceholder = styled.div`
    padding-top: 110px;
    width: 700px;
    
    
    
   @media only screen and (max-width: 780px) {
   }
   
   @media only screen  and (max-width: 780px) {
      padding-top: 50px;
   }
`;

const RightPlaceholder = styled.div`
    position: relative;
    padding-top: 90px;
        
    @media only screen and (max-width: 1000px) {
      display: none;
    }
`;

const FallosImage = styled.img`
    width: calc(50vw - 550px + 1100px - 700px);
`;

const SignupButton = styled.div`
    background: #505CD4;
    box-shadow: 0px 7px 15px rgba(2, 0, 137, 0.1);
    border-radius: 1000px;
    width: 150px;
    height: 40px;
    font-size: 14px;
    line-height: 17px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
    cursor: pointer;
    transition: 0.5s;
    
    :hover{
      opacity: 0.9;
    }
`;

const LoginSpan = styled.div`
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #505CD4;
    font-weight: bold;
    margin-right: 25px;
    cursor: pointer;
    transition: 0.5s;
    
    :hover{
      text-decoration: none;
      color: #020089;
    }
`;

const HeadingPlaceholder = styled.div`
    padding-top: 25px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    box-sizing: border-box;
  
`;

const TopLeftPlaceholder = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    
    @media only screen and (max-width: 560px) {
      width: 100%;
    }
`;

const MobileMenuButton = styled.div`
    background-color: #505CD4;
    width: 44px;
    height: 44px;
    border-radius: 100px;
    background-image: url(${menuIcon});
    background-position: center;
    background-repeat: no-repeat;
    background-size: 28px;
    cursor: pointer;
    display: none;
     @media only screen and (max-width: 560px) {
      display: block;
    }
`

const LinksPlaceholder = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    
      @media only screen and (max-width: 1060px) {
      display: none;
    }
`;

const LinkItem = styled.a`
    color: #1D293F;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 32px;
    margin-right: 30px;
    text-decoration: none;
    cursor: pointer;
    transition: .2s;
    
    :hover{
      color: #ff901f;
    }
`;

const TopRightPlaceholder = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
     
    @media only screen and (max-width: 560px) {
      display: none;
    }
`;

const RightLink = styled.div`
    margin-right: 30px;
    font-weight: bold;
`;

const LogoImg = styled.img`
    height: 48px;
    width: 168px;
    margin-right: 50px;
    margin-bottom: 0px;
    
    
     @media only screen and (max-width: 560px) {
      margin: auto;
      text-align: center;
    }
    
    
    @media only screen  and (max-width: 560px) {
     text-align: left;
     margin: inherit;
    }

`;
