import React, { useState, useEffect, useRef, useCallback, useReducer } from "react"

import styled from "styled-components"
import bg from '../assets/images/bg_2.svg'

export default function Block3() {
  return (
    <Wrapper>
      
    </Wrapper>
  )
}

const Wrapper = styled.div`
  background-image: url(${bg});
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 760px;
`
