import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled from 'styled-components';
import { Content } from "../content"
import { useScrollPosition } from '@n8tb1t/use-scroll-position'
import FormattedMessage from "../../elements/FormattedMessage"

const ITEMS = [
    {
        name: <FormattedMessage id="narrowHorSection.1.title"/>,
        text: <FormattedMessage id="narrowHorSection.1.description"/>
    },
    {
        name: <FormattedMessage id="narrowHorSection.2.title"/>,
        text: <FormattedMessage id="narrowHorSection.2.description"/>
    },
    {
        name: <FormattedMessage id="narrowHorSection.3.title"/>,
        text: <FormattedMessage id="narrowHorSection.3.description"/>
    },
    {
        name: <FormattedMessage id="narrowHorSection.4.title"/>,
        text: <FormattedMessage id="narrowHorSection.4.description"/>
    },
    {
        name: <FormattedMessage id="narrowHorSection.5.title"/>,
        text: <FormattedMessage id="narrowHorSection.5.description"/>
    }

]

export default function NarrowHorSection(props) {

    const [animate, setAnimate] = useState({
        blocks: true
    });

    useScrollPosition(({ prevPos, currPos }) => {
        const yValue = Math.abs(currPos.y);
        const xValue = Math.abs(currPos.x);
        if(!animate.blocks){
            setAnimate({
                blocks: (xValue <=500 ) ? true :yValue >= 200
            })
        }
    })

    return (
        <Wrapper>

            <Content >
              <Inner className={animate.blocks ? 'animate__animated animate__lightSpeedInRight animate__delay-1s' : 'display-none'}>
                  {ITEMS.map((it, i) => {
                      return (
                        <Item>
                            <Name>
                                {it.name}
                            </Name>
                            <Text>
                                {it.text}
                            </Text>
                        </Item>
                      )
                  })}

              </Inner>
          </Content>
        </Wrapper>
    );
}

const Wrapper = styled.div`
    margin-top: 60px;    
    margin-bottom: 60px;    
    
    @media only screen  and (min-width: 375px) and (max-width: 500px) {
     margin: initial;
   }
    @media only screen  and (max-width: 375px) {
     margin-top: 40px;
   }
   
`;

const Inner = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    
    box-sizing: border-box;
    margin: 0 auto;
    
    @media(min-width: 500px) and (max-width: 860px){
      flex-wrap: wrap;
      padding-bottom: 80px;
    }
    
        
    @media only screen  and (min-width: 370px) and (max-width: 500px)  {
      flex-direction: row;
      flex-wrap: wrap;
    }
    
    @media only screen  and (max-width: 370px) {
     flex-direction: column;
     justify-content: center;
   }
`;

const Item = styled.div`
    text-align: center;
    padding-left: 10px;
    padding-top: 20px;
    padding-right: 10px;
    flex: 1;
    font-size: 16px;

     @media(min-width: 500px) and (max-width: 860px){
      flex: 1 1 40%;
      justify-content: center;
      padding: 30px 0 0 0;
    }
    
      @media only screen  and (min-width: 370px) and (max-width: 500px) {
      font-size: 12px;
      width: 130px;
      flex: 1 1 40%;
      padding: 3px;
      align-items: center;
      justify-content: center;
      margin-bottom: 30px;
    }
    
    @media only screen  and (max-width: 370px) {
      font-size: 12px;
      text-align: left;
      width: 100%;
      margin-bottom: 5px;
   }
    
    
`;

const Name = styled.div`
    font-weight: bold;
    line-height: 30px;
    color: #1D293F;
    margin-bottom: 7px;
    
    @media(max-width: 500px){
      line-height: 14px;
      margin-bottom: 12px;    
    }
    
`;

const Text = styled.div`
    line-height: 22px;
    color: #7C8087;
    
        @media(max-width: 500px){
          line-height: 14px;
    }
`;
