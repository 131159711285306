import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import axios from 'axios'

import flag from 'country-code-emoji';
import { Content } from "../content"
import FormattedMessage from "../../elements/FormattedMessage"

const bsImage = require('./images/b_s.svg')

export default function ProxySection(props) {
    const {
        items = [
            {
                code: 'RU',
                name: 'Россия'
            },
            {
                code: 'UA',
                name: 'Украина'
            },
            {
                code: 'KZ',
                name: 'Казахстан'
            },
            {
                code: 'US',
                name: 'США'
            },
            {
                code: 'CA',
                name: 'Канада'
            },
            {
                code: 'DE',
                name: 'Германия'
            },
            {
                code: 'FR',
                name: 'Франция'
            },
            {
                code: 'BY',
                name: 'Беларусь'
            },
            {
                code: 'FI',
                name: 'Финляндия'
            },
            {
                code: 'ES',
                name: 'Испания'
            },
            {
                code: 'JP',
                name: 'Япония'
            },

        ]
    } = props;

    const onClick = () => {
        window.location.href = 'https://proxy.broker/app/?signup'
    }

    return (
      <Wrapper>
          <Content>
              <BlockWrapper>

                  <Left>

                      <Name>
                          <FormattedMessage id="proxySection.title"/>

                      </Name>

                      <TablePlaceholder>
                          {items.map((a, i) => {
                              return (
                                <Row key={i}>
                                    <CWrappr>
                                        <div style={{marginRight: 10}}>
                                            {`${flag(a.code)}`}
                                        </div>
                                        <CountryTitle><FormattedMessage id="proxySection.buyCountry"/> <FormattedMessage id={`proxySection.country.${a.code}`}/> </CountryTitle>
                                    </CWrappr>
                                    <BuySection>
                                        <Buy onClick={() => {
                                            onClick();
                                        }}> <FormattedMessage id="proxySection.buy"/></Buy>
                                    </BuySection>

                                </Row>
                              )
                          })}
                      </TablePlaceholder>

                      <BottomPlaceholder>
                          <BottomButton onClick={() => {
                              onClick();
                          }}>
                              <FormattedMessage id="proxySection.action"/>
                          </BottomButton>
                      </BottomPlaceholder>

                  </Left>

                  <Right>

                      <CoolItem>
                          <CoolImg src={require('./images/globa.svg')}/>
                          <ItemText>
                              <FormattedMessage id="proxySection.feature.1"/>
                          </ItemText>
                      </CoolItem>

                      <CoolItem>
                          <CoolImg style={{background: 'white'}} src={require('./images/com_df.svg')}/>
                          <ItemText>
                              <FormattedMessage id="proxySection.feature.2"/>
                          </ItemText>
                      </CoolItem>

                      <CoolItem>
                          <CoolImg src={require('./images/bar_group.svg')}/>
                          <ItemText>
                              <FormattedMessage id="proxySection.feature.3"/>
                          </ItemText>
                      </CoolItem>
                  </Right>

              </BlockWrapper>
          </Content>
      </Wrapper>

    );
}

const Wrapper = styled.div`
  
`;


const CWrappr = styled.div`
  display: flex;
  align-items: center;
  
`;

const CountryTitle = styled.span`

  
  @media only screen and (max-width: 500px) {
      font-size: 12px;
      line-height: 18px;
  }
 
`
const BuySection = styled.div`
    display: flex;
    justify-content: space-between;
    width: 80px;
`

const Buy = styled.div`
   color: #505CD4;
   cursor: pointer;
   font-weight: 600;
   width: 80px;
   transition: 0.3s;
   :hover{
     text-decoration: none;
     color: #FF911D;
     opacity: 0.9;
   }
   
     @media only screen and (max-width: 500px) {
      font-size: 16px;
  }
`


const BottomPlaceholder = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
`;

const BottomButton = styled.div`
    background: #FF911D;
    box-shadow: 0px 4px 8px rgba(255, 145, 29, 0.2);
    width: 270px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 60px;
    border-radius: 1000px;
    cursor: pointer;
    color: white;
    transition: 0.5s;    
    
    :hover{
      opacity: 0.8;
    }
`;


const ItemsPlaceholder = styled.div`
    
`;

const CoolImg = styled.img`
    height: 78px;
`;

const ItemText = styled.div`
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: #1D293F;
    margin-top: 30px;
`;

const CoolItem = styled.div`
    margin-bottom: 60px;
    :last-of-type{
      margin-bottom: 0px;
    }
`;

const breakWidth = 1100;

const ButtonPlaceholder = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 40px;
`;

const Button = styled.div`
    box-sizing: border-box;
    box-shadow: 0px 7px 15px rgba(2, 0, 137, 0.1);
    border-radius: 100px;
    border: 1px solid #6774FF;
    cursor: pointer;
    
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #6774FF;
    
    height: 40px;
    
    padding-left: 30px;
    padding-right: 30px;
    
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
    :hover{
      opacity: 0.9;
      background: #6774FF;
      color: white;
    }
`;

const BlockWrapper = styled.div`
    margin: 0 auto;

    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 120px;
    margin-bottom: 20px;
    
    @media(max-width: ${breakWidth}px){
      width: 100%;
    }
    
`;

const Row = styled.div`
    border-bottom: 1px solid #F2F1F0;
    padding-bottom: 10px;
    margin-bottom: 15px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    
   
`;

const TablePlaceholder = styled.div`
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 30px;
    color: #7C8087;
`;

const Name = styled.div`
    font-size: 36px;
    line-height: 56px;
    text-align: center;
    letter-spacing: -0.827586px;
    color: #1D293F;
    margin-bottom: 40px;
    
        
     @media only screen and (max-width: 500px) {
      font-size: 18px;
    }
`;

const Left = styled.div`
    width: 600px;
    text-align: left;
    background: #FFFFFF;
    box-shadow: 0px 30px 64px rgba(23, 48, 164, 0.1);
    border-radius: 20px;
    padding: 50px;
    
    @media only screen and (min-width: 500px) and (max-width: 1150px) {
      margin: auto;
    }
    
     @media only screen and (max-width: 500px) {
      width: 100%;
      box-shadow: none;
      border-radius: 0;
      padding: 0;
    }
`;

const Right = styled.div`
    flex: 1;
    box-sizing: border-box;
    padding-left: 120px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    
    @media only screen and (max-width: 1150px) {
      display: none;
    }
`;

const BsImage = styled.img`
    width: 100%;
`;
