import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import axios from 'axios'
import { Content } from "../content"
import iconBg from './images/about_icon_bg.svg';
import FormattedMessage from "../../elements/FormattedMessage"
import Slider from "../carousel"
const bsImage = require('./images/sky__icon.svg')

export default function TestimonialsSection(props) {
    const reviews = ITEMS.map((a, i) => {
        return (
          <Item key={i}>
              <TopItemPlaceholder>
                  <ItemLeft>
                      <TopItemPlaceholder>
                          <TopAvatar src={a.avatar}/>
                      </TopItemPlaceholder>
                      <div>
                          <ItemName>
                              {a.name}
                          </ItemName>
                          <ItemLabel>
                              {a.label}
                          </ItemLabel>
                      </div>
                  </ItemLeft>
                  <ItemRightImg src={a.socImg}/>
              </TopItemPlaceholder>
              <ItemContent>
                  {a.text}
              </ItemContent>
          </Item>
        )
    });

    return (
        <Wrapper>
            <Content>

                <Heading id="reviews">
                    <FormattedMessage id="testimonialsSection.title"/>
                </Heading>

                <SubHeading>
                    <FormattedMessage id="testimonialsSection.desc.a"/>
                    <br/>
                    <FormattedMessage id="testimonialsSection.desc.b"/>
                </SubHeading>


                <TestemotionalContent>
                   <SliderContainer>
                       <Slider items={reviews}/>
                   </SliderContainer>

                    <ReviewsContainer>
                        {reviews}
                    </ReviewsContainer>
                </TestemotionalContent>

            </Content>
        </Wrapper>
    );
}

const breakWidth =1100;

const TopItemPlaceholder = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;;
`;

const ItemContent = styled.div`
    font-size: 14px;
    line-height: 26px;
    color: #7C8087;
    margin-top: 20px;
`;

const ItemName = styled.div`
    font-size: 18px;
    line-height: 32px;
    color: #1D293F;
`;

const ItemLabel = styled.div`
    font-size: 13px;
    line-height: 20px;
    color: #7C8087;
`;

const TopAvatar = styled.img`
    border-radius: 1000px;
    width: 42px;  
    height: 42px;
    margin-right: 13px;
    
`;

const BottomPlaceholder = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
`;

const BottomButton = styled.div`
    background: #FF911D;
    box-shadow: 0px 4px 8px rgba(255, 145, 29, 0.2);
    width: 270px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 60px;
    border-radius: 1000px;
    cursor: pointer;
    color: white;
    :hover{
      opacity: 0.8;
    }
`;

const Item = styled.div`
    padding: 19px;
    margin-bottom: 40px;
    width: calc((100% - 80px) / 3.0);
    box-sizing: border-box;
    display: inline-block;
    vertical-align: top;
    background: #FFFFFF;
    border: 1px solid #E2E4E8;
    border-radius: 10px;
    margin-right: 40px;
    :nth-of-type(3n){
      margin-right: 0px;
    }
   @media only screen and (min-width: 500px) and (max-width: 880px) {
    width: 100%;
      
      :last-child {
        margin-bottom: 0;
      }
    } 
   
    @media only screen and (max-width: 500px){
      width: 100%;
      margin: 0;
    }
`;


const ItemLeft = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const ItemRightImg = styled.img`
    height: 40px;
`;


const Wrapper = styled.div`
    box-sizing: border-box;
    box-sizing: border-box;
    padding-bottom: 100px;
    background: #F7F8FA;
    background-position-y: 60px;
    padding-top: 100px;
    background-image: url(${bsImage});
    background-repeat: no-repeat;
   
    
`;

const SubHeading = styled.div`
    text-align: center;
    font-size: 18px;
    line-height: 30px;
    color: #7C8087;
    margin-bottom: 45px;
    margin-top: 15px;
    
     @media only screen and (max-width: 500px) {
      text-align: center;
      font-size: 14px;
      line-height: 17px;
    }
`;

const Heading = styled.div`
    font-weight: bold;
    font-size: 48px;
    line-height: 56px;
    color: #1D293F;
    text-align: center;
    
      @media only screen and (max-width: 500px) {
      text-align: center;
      font-size: 24px;
      line-height: 28px;

    }
`;

const ReviewsContainer = styled.div`
  display: block;
  
    @media(max-width: 500px){
      display: none;
    }
`;


const SliderContainer = styled.div`
  display: none;
  
    @media(max-width: 500px){
      display: block;
    }
`;

const TestemotionalContent = styled.div`
    margin: 0 auto;
    
    @media only screen and (min-width: 500px) and (max-width: 860px) {
      display: flex;
      flex-direction: column;
    }
    
    @media(max-width: 500px){
     
    }
`;

const FaqName = styled.div`
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: #2C2E30;
    margin-bottom: 15px;
`;

const FaqText = styled.div`
    font-size: 16px;
    line-height: 26px;
    color: #7C8087;
`;

const FaqItem = styled.div`
    margin-bottom: 80px;
`;

const ITEMS = [
    {
        avatar: require('./images/users/u1.jpg'),
        name: 'Ant Andr',
        label: 'SEO-специалист',
        socImg: '',
        text: `Очень круто: я искал самые дешевые мобильные прокси Москвы и смог найти их у вас. У вас, как в Яндекс.Маркете, можно отсортировать предложения продавцов по цене (я естественно сортировал по самым низким ценам), и нашел приватные мобильные прокси Москвы всего за 1200 руб./мес. безлимит! У вас крутая идея сервиса - спасибо вам, вы молодцы, развивайтесь дальше`
    },
    {
        avatar: require('./images/users/u2.jpg'),
        name: 'Maks',
        label: 'SMM - Авито/Юла/Olx',
        socImg: '',
        text: `Ребят, у вас реально удобный функционал: поддерживаете и HTTP и SOCKS5, подробная аналитика по моему прокси, очень удобно вами пользоваться!`
    },
    {
        avatar: require('./images/users/u3.jpg'),
        name: 'Арсен Аваков',
        label: 'Работает с ботом ВТО.ПЕ',
        socImg: '',
        text: `Нигде не мог, а у вас получилось найти мобильные прокси в нужном мне непопулярном регионе России! Спасибо вам также за то, что помогли мне разобраться с тем, как правильно использовать мобильные прокси для бота ВТО.ПЕ`
    },
    {
        avatar: require('./images/users/u4.jpg'),
        name: 'Kirill M',
        label: 'Арбитраж трафика в Google',
        socImg: '',
        text: `У вас нашел прямых продавцов мобильных прокси, причем из разных стран Европы! Все продавцы очень адекватные, приятные ребята. С некоторыми, правда приходится при помощи гугл-переводчика общаться, но очень круто, что вы помогаете в трудные моменты с переводом переписки! Спасибо за удобный функционал! А за ваш телеграм-бот - особый респект👍!!!`
    },
    {
        avatar: require('./images/users/u5.jpg'),
        name: 'Nes Quik',
        label: 'Арбитраж трафика в Фб',
        socImg: '',
        text: `У меня максимальное доверие к проектам Евгения, руководителя проекта, так как сам являюсь клиентом его проекта iProxy.online и видел практически с самого начала как они с командой развивали свое детище и как они выросли. Очень рад, что вы, наконец, запустили биржу! Благодаря вам я смог найти продавцов мобильных прокси Тайланда. Надеюсь, в скором будущем появится еще больше стран (желательно Азии).`
    },
    {
        avatar: require('./images/users/u6.jpg'),
        name: 'Антон Полищук',
        label: 'Работает с БК',
        socImg: '',
        text: `И откуда у вас только берутся такие неординарные идеи по мобильным прокси? Сначала вы придумали iProxy.online, где я на изи смог поднять для себя мобильные прокси с телефона, теперь вы организовали маркетплейс, где я могу выбирать продавцов из нужных мне регионов! Интересно, какой же следующий проект вы сделаете?)`
    }

]
