import React, { useState, useEffect, useRef, useCallback, useReducer } from "react"
import Carousel, { Dots } from '@brainhubeu/react-carousel';
import '@brainhubeu/react-carousel/lib/style.css';

import styled from "styled-components"

export default function Slider({
  items = []
                               }) {

  const [active, setActive] = useState(0);

  return (
    <Wrapper>
      <Carousel  centered
                 infinite
                 value={active}
                 onChange={(e) => setActive(e)}
                 >

        {items.map((a, i)=> (<Content key={i}>{a}</Content>))}
      </Carousel>
      <DotContainer>
        {items.map((a, i)=> (<SupperDot key={i} isActive={i === active} onClick={() => setActive(i)}/>))}
      </DotContainer>

    </Wrapper>
  )
}

const Wrapper = styled.div``
const DotContainer = styled.div`
display: flex;
justify-content: center;
text-align: center;
align-items: center;
margin-top: 10px;
`
const SupperDot = styled.div`
background-color: ${props => !props.isActive ? 'rgba(103,116,255,0.5)' : 'rgba(103,116,255,1)'};
border-radius: 100px;
height: 10px;
width: 10px;
margin: 5px;
cursor: pointer;

:hover {
  background-color: rgba(103,116,255,1)
}
`
const Content = styled.div`
  height: 100%;
  display: flex;
  padding: 10px;
`

