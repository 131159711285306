import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import axios from 'axios'
import { Content } from "../content"
import FormattedMessage from "../../elements/FormattedMessage"

const bsImage = require('./images/buy__icon.svg')

export default function BuySellSection(props) {
    const {
        name = 'buy.title',
        description = `buy.description`,
        buttonName = `buy.action`,
        id,
        onClick = () => {

        },
        image = bsImage
    } = props;

    return (
      <Wrapper>
          <Content>

              <BlockWrapper id={id}>
                  <Left>

                      <Name>
                          <FormattedMessage id={name}/>

                      </Name>
                      <ContentImage src={image}/>

                      <Description >
                          <FormattedMessage id={description}/>
                      </Description>

                      <ButtonPlaceholder>
                          <Button onClick={() => {
                              onClick();
                          }}>
                              <FormattedMessage id={buttonName}/>
                          </Button>
                      </ButtonPlaceholder>

                  </Left>

                  <Right>
                      <BsImage src={image}/>
                  </Right>

              </BlockWrapper>
          </Content>
      </Wrapper>
    );
}

const breakWidth = 1100;

const ButtonPlaceholder = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 40px;
    @media only screen  and (min-width: 500px) and (max-width: 880px) {
      justify-content: center;
    }
    
     @media only screen and (max-width: 500px) {
      justify-content: center;
      margin-top: 20px;
    }
`;

const Button = styled.div`
    box-sizing: border-box;
    box-shadow: 0px 7px 15px rgba(2, 0, 137, 0.1);
    border-radius: 100px;
    border: 1px solid #6774FF;
    cursor: pointer;
    
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #6774FF;
    
    height: 40px;
    
    padding-left: 30px;
    padding-right: 30px;
    
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: 0.3s;
    :hover{
      opacity: 0.9;
      background: #6774FF;
      color: white;
      box-shadow: 0px 7px 15px rgba(2, 0, 137, 0.25);

    }
`;

const Wrapper = styled.div`

`

const BlockWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 120px auto 20px;
    
    @media only screen and (max-width: 500px) {
          margin-top: 10px;
    }
    
`;

const Description = styled.div`
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 30px;
    color: #7C8087;
    
    @media only screen  and (min-width: 500px) and (max-width: 800px) {
      text-align: justify;
    }
    
     @media only screen and (max-width: 500px) {
     font-size: 14px;
     text-align: center;
    }
`;

const Name = styled.h3`
    font-weight: bold;
    font-size: 48px;
    line-height: 56px;
    text-align: left;
    color: #1D293F;
    margin-bottom: 25px;
    
     @media only screen and (min-width: 500px) and (max-width: 800px) {
      text-align: center;
    }
    
    @media only screen and (max-width: 500px) {
      text-align: center;
      font-size: 24px;
      line-height: 28px;

    }
`;

const Left = styled.div`
    width: 520px;
    text-align: left;
     @media only screen and (max-width: 800px) {
      width: 100%;
    }
`;

const Right = styled.div`
    flex: 1;
    box-sizing: border-box;
    padding-left: 40px;
    @media only screen and (max-width: 800px) {
      display: none;
    }
    
`;

const BsImage = styled.img`
    width: 100%;
`;


const ContentImage = styled.img`
    width: 100%;
    display: none;
     @media only screen and (max-width: 800px) {
      display: block;
      max-width: 400px;
      margin: 40px auto;
    }
`;
