import React, { useState, useEffect, useRef, useCallback, useReducer } from "react"
import moment from "moment"
import uuid from "uuid"
import styled from "styled-components"
import { Content } from "../content"
import vBg from './images/video-bg.svg';
import FormattedMessage from "../../elements/FormattedMessage"

export default function VideoSection() {
  return (
    <Wrapper id="video-preview">
      <Content>
        <Heading>
          <FormattedMessage id="videoSection.title"/>
        </Heading>
        <Video>
          <iframe width="600" height="400" src="https://www.youtube.com/embed/sZELzeuTJIM" frameBorder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen></iframe>
        </Video>
      </Content>
    </Wrapper>
  )
}

const Video = styled.div`
  background-image: url(${vBg});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 100%;
  max-width: 1000px;
  height: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  
   
    @media only screen and (max-width: 980px) {
     background-image: none;
    } 
    
  
  iframe {
  
    @media only screen and (min-width: 980px) {
     margin-right: 3.2%;
    margin-top: 10px;   
    } 
  width:741px;
  height: 450px;
  
  }
`

const Wrapper = styled.div`
 padding: 70px 0 10px 0;
`

const Heading = styled.div`
    font-weight: bold;
    font-size: 48px;
    line-height: 56px;
    color: #1D293F;
    text-align: center;
    
      @media only screen and (max-width: 500px) {
      text-align: center;
      font-size: 24px;
      line-height: 28px;

    }
`;
