import React, { useEffect, useState } from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import { Block1 } from "../blocks"
import styled from "styled-components"
import Screen from "../components/screen"
import Block2 from "../blocks/block2"
import Block3 from "../blocks/block3"
import Block4 from "../blocks/block4"
import Block5 from "../blocks/block5"
import Block6 from "../blocks/block6"
import Block7 from "../blocks/block7"
import BlockFooter from "../blocks/block-footer"
import TopSection from "../components/sections/TopSection";
import JohnSection from "../components/sections/JohnSection";
import WhySection from "../components/sections/WhySection";
import BuySellSection from "../components/sections/BuySellSection";
import FaqSection from "../components/sections/FaqSection";
import FooterSection from "../components/sections/FooterSection";
import TestimonialsSection from "../components/sections/TestimonialsSection";
import ProxySection from "../components/sections/ProxySection";
import NarrowHorSection from "../components/sections/NarrowHorSection";
import Articles from "../components/sections/Articles"

import './style.css'
import { generateLanguage } from "../services/langService"
import VideoSection from "../components/sections/VideoSection"

const sellImg = require("../components/sections/images/sel__icon.svg")

const IndexPage = () => {


  const [fontsReady, setFontsReady] = useState(false)

  useEffect(() => {
    // document.fonts.ready.then(() => {
    //     setFontsReady(true);
    // });
    setTimeout(() => {
      setFontsReady(true)
    }, 800)


  }, [])

  useEffect(() => {
    document.querySelectorAll('a[href^="#"]').forEach(anchor => {
      anchor.addEventListener('click', function (e) {
        e.preventDefault();

        document.querySelector(this.getAttribute('href')).scrollIntoView({
          behavior: 'smooth'
        });
      });
    });
  }, [])


  // if (fontsReady == false){
  //     return '';
  // }
  const intl = generateLanguage();

  return (
    <Wrapper>

      {fontsReady == true ? null :
        <EugeneFuckoffFontsOverlay >
          <Loader>
            <div className="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
          </Loader>
      </EugeneFuckoffFontsOverlay>}

      <SEO
        description={"PROXY.broker связывает продавцов и покупателей мобильных прокси напрямую, без посредников; гарантирует обеим сторонам уверенность в работе, минимальную стоимость и качество мобильных прокси!"}
        lang={"ru"}
        keywords={["proxy", "mobile proxy", "мобильные прокси", "мобильные прокси своими рука", "create mobile proxy", "self hosted proxy"]}
        title={"Продать или купить мобильные прокси. Дешевые мобильные прокси."}/>

      <TopSection/>

      <NarrowHorSection/>

      <JohnSection/>

      <WhySection/>

      <BuySellSection id={"buy"}/>

      <ProxySection/>

      <BuySellSection
        id={"sell"}
        onClick={() => {
          window.location.href = `https://proxy.broker/app/#/`
      
        }}
        name={"sell.title"}
        description={`sell.description`}
      
        buttonName={`sell.action`}
        image={sellImg}
      />
    <VideoSection/>
            {/*<Articles/>*/}
            <TestimonialsSection/>

      <FaqSection/>

      <FooterSection/>
    </Wrapper>
  )

}

export default IndexPage

const Wrapper = styled.div`
    font-family: Montserrat;
    overflow-x: hidden;
`

const EugeneFuckoffFontsOverlay = styled.div`
    position: fixed;
    z-index: 1000;
    background: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    box-sizing: border-box;
`

const Loader = styled.div`
    
`;
