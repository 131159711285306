import React, { useState, useEffect, useRef, useCallback, useReducer } from "react"

import styled from "styled-components"

export default function Block6() {
  return (
    <Wrapper>

    </Wrapper>
  )
}

const Wrapper = styled.div`
  min-height: 600px;
`
