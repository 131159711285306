import React, { useState, useEffect, useRef, useCallback, useReducer } from "react"
import styled from "styled-components"

export  function Content({children}) {
  return (
    <Wrapper>
      {children}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  max-width: 1280px;
  margin: auto;
  padding: 0 20px;
  box-sizing: border-box;
  min-width: 320px;
`
