import React, { useState, useEffect, useRef, useCallback, useReducer } from "react"
import bg from './../assets/images/Backgroundbg_block1.svg'
import styled from "styled-components"
import Header from "../components/header"
import { Content } from "../components"

export function Block1() {
  return (
    <Wrapper>
      <Content>
        <Header/>

      </Content>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  background-image: url(${bg});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 860px;
`
