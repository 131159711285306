import React, { useState, useEffect, useRef, useCallback, useReducer } from "react"

import styled from "styled-components"

export default function FeatureContentSlim({

                                           }) {
  return (
    <Wrapper>
      Уведомления о смене IP-адреса в Телеграмме
    </Wrapper>
  )
}

const Wrapper = styled.div`
  background: #6774FF;
  border-radius: 10px;
  width: 245.15px;
  padding: 10px 20px;
  box-sizing: border-box;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  color: #FFFFFF;

`
