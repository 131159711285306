import React, { useState, useEffect, useRef, useCallback, useReducer } from "react"
import styled from "styled-components"

export default function FeatureContent({
  icon = null,
  title = null,
  description = null

                                       }) {
  return (
    <Wrapper>
      <Content>
        <Icon icon={icon}/>
        <Title>{title}</Title>
        <Description>
          {description}
        </Description>
      </Content>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  max-width: 400px;
  background: #F7F8FA;
  border-radius: 10px;
  box-sizing: border-box;
  padding: 5px;
`

const Content = styled.div`
  padding: 50px 30px 30px;
`

const Icon = styled.div`
 border-radius: 4px;
 width: 75px;
 height: 75px;
 background-color: red;
 background-position: center;
 background-repeat: no-repeat;
 background-image: url(${props => props.icon};);
`


const Title = styled.div`
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  color: #1D293F;
  margin: 30px 0 24px 0;
`

const Description = styled.div`
  font-size: 16px;
  line-height: 26px;
  color: #7C8087;
`
